.gallery {
    width: 90%;
    margin: auto;
    padding:  .5rem;
}

.galleryHeader {
    width: 90%;
    margin: 7rem auto auto auto;
    font-size: 36pt;
    padding:  .5rem;
}

.galleryWrapper {
    color: white;
    font-size: larger;
}