.statsbar {
    width:  90%;
    margin: 2em auto 0em auto;
    border-collapse: collapse;
}

.statsbar tr {
    height: 25vh;
}

.statsbar td {
    border: none;
    padding: 0px;
}

.unsold {
    background: pink;
}

.sold {
    background: lightcoral;
}

.unshared {
    background: lightgreen;
}

.shared {
    background: mediumseagreen;
}

.underStats {
    width: 90%;
    margin: auto;
    text-align: center;
    padding: 1em;
}