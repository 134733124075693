.imageContainer {
  height: calc(100vh - 5em);
  width: 60%;
  float: left;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 5em;
  background: white;
}

.imageContainer img {
  max-height: 70%;
  max-width: 70%;
}

.infoContainer {
  float: right;
  z-index: 10;
  width: 40%;
  height: calc(150vh);
  margin-top: 5em;
}

.infoContainer button {
  background: white;
  padding: 1em 2em;
  margin: 0rem 1rem;
  border-width: 4px;
  font-family : inherit;
}

.detailText {
  padding: 1rem;
}

.error {
  color: red;
}

.available {
  color: mediumseagreen;
}

@media only screen and (max-width: 1100px) {
  .imageContainer {
    float: unset;
    width: 100%;
    height: 100vw;
    position: relative;
  }

  .infoContainer {
     float: unset;
     width: 100%;
     height: unset;
  }
}