.hometable td {
  width: calc(100vw/4);
  transition: 1s;
}

.hometable td:hover {
  background: #c9bab9;
}

.forSale {
  background: #c9bab9;
}

.notForSale {
  background: white;
}

.available {
  color: mediumseagreen;
}

.textContainer {
  height: 50px;
  text-align: center;
}

.previewContainer {
  height: calc(70vw/4);
  align-items: center;
  justify-content: center;
  display: flex;
}

.buyContainer {
  height: 200px;
  text-align: center;
}

.imgPreview {
  max-width: 70%;
  max-height: 70%;
}

.viewButton {
  background: white;
  padding: 1em 2em;
  border-width: 4px;
  font-family : inherit;
}

@media only screen and (max-width: 500px) {
  .hometable td {
    display:block;
    width: 100vw;
  }

  .previewContainer {
    height: calc(70vw);
    text-align: center;
  }
}