.header {
  height: 5em;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 15;
  top: 0;
}

.header button {
  float: right;
  margin: 1em;
  background: white;
  padding: 1em 2em;
  border-width: 4px;
  font-family : inherit;
}

.message {
  float: right;
  margin: 1em;
  padding: .5em;
  color: #4c4c4d
}

@media only screen and (max-width: 500px) {
  .header button {
    float: right;
    margin: 1em 1em 0em 1em;
    background: white;
    padding: 1em 2em;
    border-width: 4px;
    font-family : inherit;
  }

  .message {
    float: right;
    margin: 0em;
    padding: 0em 1em;
    color: #4c4c4d
  }
}

.address {
  color: black;
}

.menu {
  padding: 1em;
  z-index: 15;
  position: relative;
}

.menuItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 2em;
  position: absolute;
  background: white;
  left: 0;
  transition: transform 0.5s ease-in-out;
}

.menuClosed {
  transform: translateY(-150%);
  z-index: 10;
}

.menuItems a {
  text-decoration: none;
  text-transform: uppercase;
  padding: 2em;
  transition: 0.5s;
  color: #4c4c4d;
}

.menuItems a:hover {
  background: #ebe6e6;
}

.menuItems a:visited {
  color: #4c4c4d;
}