.leaderboardHeader {
    width: 90%;
    margin: 7rem auto auto auto;
    padding-bottom:  .5rem;
}

.leaderboard {
    width: 90%;
    margin: auto;
    padding:  .5rem;
}