.modal {
  width: 50vw;
  position: fixed;
  top: 5em;
  z-index: 15;
  left: 20vw;
  background: white;
  padding: 1em;
  border: black 3px solid;
  border-radius: 25px;
}

.close {
  float: right;
}

.modal-contents {
  margin: 2em 0em 1em 0em;
}

.modal-contents input {
  padding: .2rem;
  border: 1px solid #ABB0B2;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
}

.modal-contents span {
  display: block;
  margin-bottom: 3px;
}

.modal .submit {
  margin-top: 1rem;
  clear: both;
    background-color: #aaa;
    border: 0 none;
    border-radius: 4px;
    transition: all 0.23s ease-in-out 0s;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
}