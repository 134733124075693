.hometable {
  padding-top: 4em;
}

.hometable tr {
  height: calc(70vw/4);
}

@media only screen and (max-width: 500px) {
  .hometable {
    display: block;
  }

  .hometable tr {
    height: calc((70vw + 200px) * 4);
    display: block;
  }
}